@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "font-reg";
    src: url("./assets/font/ProximaNova-Regular.otf");
}

@font-face {
    font-family: "font-bold";
    src: url("./assets/font/Proxima\ Nova\ Bold.otf");
}

@font-face {
    font-family: "font-extra-bold";
    src: url("./assets/font/Proxima\ Nova\ Extrabold.otf");
}

@font-face {
    font-family: "font-semibold";
    src: url("./assets/font/Proxima\ Nova\ Semibold.otf");
}

body{
    font-family: 'font-reg';
}

.font-bold{
    font-family: 'font-bold';
}

.font-semibold{
    font-family: 'font-semibold';
}

.font-extra-bold{
    font-family: 'font-extra-bold';
}

.blur-header{
background: rgba(255, 255, 255, 0.02);
backdrop-filter: blur(3.2px);
-webkit-backdrop-filter: blur(3.2px);
}

.blur-small-boxes{
    background:rgb(59 48 105);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.card-label input:checked + label{
    color: #fff;
    background: linear-gradient(to right,#C121E5,#8B25F5);
}
.card-label input:checked + label img{
    filter: brightness(0) invert(1);
}

.animate_one{
    opacity: 0;
    font-size: 20px;
}
.avatar-splide {
    position: relative;
}
.avatar-splide .splide__arrows{
    display: flex;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 0px;
    top: -12px;
}
.avatar-splide .splide__arrow{
    position: static;
    width: 26px;
    height: 26px;
    background-color: #2B2433;
    border-radius: 7.8px;
}
.avatar-splide .splide__arrow svg{
    fill: #fff;
    width: 16px;
}

/* common */
.no-scroll::-webkit-scrollbar{
    display: none;
}
.rotate-dynamic{
    transform:rotate(-445deg)
  }
  .coin-animation {
      position: absolute;
      bottom: 0;
      width: 24px;
      height: 24px;
      opacity: 0;
      animation: popCoin 1.5s ease-out forwards;
      pointer-events: none;
     
  }

  @keyframes popCoin {
      0% {
          transform: translateY(0) scale(1);
          opacity: 1;
      }

      50% {
          transform: translateY(-150px) scale(1.2);
          opacity: 1;
      }

      100% {
          transform: translateY(-500px) scale(0.8);
          opacity: 0;
      }
  }

  /* Prevent selection */
  select-none {
      user-select: none;
      -webkit-user-select: none;
      /* For Safari */
  }
  .fullscreen-layout {
      min-height: 100vh;
      height: 100vh;
      overflow: hidden;
  }

  .bg-custom-gradient {
      background: linear-gradient(221.33deg, rgba(255, 255, 255, 0.02) 33.41%, rgba(153, 153, 153, 0.018) 82%);
  }
  
/* Bounce animation */
@keyframes bounce {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}


.blue-background-gradient {
    background: radial-gradient(circle, #1A2A6C 0%, #1B3B8D 30%, #1A488E 60%, #1B2E70 100%);
   
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  
}
@keyframes wave {
    0% {
        width: 250px;
        height: 250px;
        opacity: 0.5;
    }

    100% {
        width: 350px;
        height: 350px;
        opacity: 0;
    }
}

@keyframes bounce-custom {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
        /* Adjust the scale for the bounce effect */
    }
}

.animate-bounce-custom,
.animate-wave {
    will-change: transform, opacity;
}

.animate-wave {
    animation: wave 0.3s ease-out forwards;
    position: absolute;
    border-radius: 50%;
    /* Ensures wave is circular */
    background-color: rgba(255, 223, 0, 0.5);
    /* Adjust color and opacity */
}

.animate-bounce-custom {
    animation: bounce-custom 0.2s ease;
}
 .background-gradient {
     /* Deep blue to dark blue gradient */
     background: #0D0D0D;
     position: relative;
    
     overflow: hidden;
 }

 /* Add some small sparkling stars */
.background-box {

    /* Set your desired height */
    background:linear-gradient(135deg, #8971abf5 0%,
            /* Primary Purple 500 */
            #5E00DC 30%,
            /* Slightly darker to mimic gradient blending */
            #5a0091 60%,
            /* Primary Purple 700 */
            #2f2c61f0 100%
            /* Primary Purple 200 */
        );
    border-radius: 15px;
    /* Rounded corners */
}
 /* .background-gradient::before {
     content: '';
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background: radial-gradient(circle at 10% 20%, rgba(255, 255, 255, 0.15) 1px, transparent 1px),
         radial-gradient(circle at 30% 60%, rgba(255, 255, 255, 0.1) 1px, transparent 1px),
         radial-gradient(circle at 80% 80%, rgba(255, 255, 255, 0.1) 1px, transparent 1px);
     background-size: 100px 100px;
     pointer-events: none;
     opacity: 0.7;
     z-index: 1;
 }
 */
 /* .background-gradient::after {
     content: '';
     position: absolute;
     top: 50%;
     left: 50%;
     width: 200%;
     height: 200%;
     background: radial-gradient(circle,
             rgba(255, 255, 255, 0.5) 0%,
           
             rgba(255, 255, 255, 0.2) 20%,
       
             rgba(255, 255, 255, 0.05) 50%,
         
             transparent 70%
            
         );
     transform: translate(-50%, -50%);
     opacity: 0.5;
     filter: blur(50px);
    
     pointer-events: none;
     z-index: 0;
 }  */
 .buttonBg {
     /* Option 1: Purple gradient */
     background: linear-gradient(90deg, #4A0099 0%, #6600CC 100%);

     /* Option 2: More contrasting gradient */
     background: linear-gradient(90deg, #6200EA 0%, #9D6FFF 100%);

     /* Option 3: Subtle gradient */
     background: linear-gradient(90deg, #3A0073 0%, #5000A3 100%);
 }

 .buttonBg:hover {
     /* Add slight brightness on hover */
     filter: brightness(1.2);
 }
 .primary-buttonBg {
     background: linear-gradient(90deg, #DAA520 0%, #FFD700 100%);
     color: #000000;


 }

 .primary-buttonBg:hover {
     filter: brightness(1.1);
     transform: translateY(-1px);
     transition: all 0.2s ease;
 }

 .cardDesignCustom {
     background: linear-gradient(125deg, rgba(48, 0, 115, 0.2) 0%, rgba(48, 0, 115, 0.4) 100%);
     backdrop-filter: blur(8px);
     border: 1px solid rgba(255, 255, 255, 0.1);
 }

 .no-scroll {
     overflow: hidden;
 }
 .scale-up {
     transform: scale(1.02);
   
 }
 .scale:active{
    transform: scale(.95);
 }